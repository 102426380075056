import { format, formatDistance, differenceInDays } from "date-fns";
import React from "react";
import { Link } from "gatsby";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";
import Container from "./container";
import RoleList from "./role-list";
import Video from "./vimeo-player"

import * as styles from "./project.module.css";

function Project(props) {
  const { _rawBody, title, client, categories, mainImage, members, publishedAt, relatedProjects, vimeoURL } = props;

  

  return (
    <article className={styles.root}>
      {props.mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(mainImage)
              .width(1728)
              .dpr(2)
              .url()}
            alt={mainImage.alt}
          />
          <button className={styles.playButton}>View Project</button>
        </div>
      )}
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            {_rawBody && <BlockContent blocks={_rawBody || []} />}
          </div>
          <aside className={styles.metaContent}>
            {client && client.length > 0 && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Client</h3>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{client}</li>
                  ))}
                </ul>
              </div>
            )}
            {categories && categories.length > 0 && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Content</h3>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
            {relatedProjects && relatedProjects.length > 0 && (
              <div className={styles.relatedProjects}>
                <h3 className={styles.relatedProjectsHeadline}>Related projects</h3>
                <ul>
                  {relatedProjects.map(project => (
                    <li key={`related_${project._id}`}>
                      {project.slug ? (
                        <Link to={`/project/${project.slug.current}`}>{project.title}</Link>
                      ) : (
                        <span>{project.title}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
    </article>
  );
}

export default Project;
