import React from "react";
import cn from "../lib/helpers";

import * as styles from "./vimeo-player.module.css";

function VimeoPlayer({url}){
  

  return (
    <div className={styles.root}>
      <iframe className={styles.player} src="https://player.vimeo.com/video/695535714?h=175fcab0c5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
};

export default VimeoPlayer;
